import React from 'react';
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
// Redux Store
import { Provider } from 'react-redux'
import store from './store/store'
import './App.scss'
import { listPictures } from './store/actions/picturesActions';
import { listVideos } from './store/actions/videosActions';
import { listInfo } from './store/actions/infoActions';

store.dispatch(listPictures())
store.dispatch(listVideos())
store.dispatch(listInfo())

ReactDOM.render((
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>
), document.getElementById('app'))
serviceWorker.unregister();
