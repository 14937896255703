import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FooterBar from "../components/FooterBar";

import SinglePicture from "../components/SinglePicture"

import { motion } from "framer-motion";

const PrintsScreen = (props) => {
    const matchPoints = props.matchPoints;
    const picturesList = useSelector((state) => state.picturesList);
    const infoList = useSelector((state) => state.infoList);


    const { loadingPictures } = picturesList;
    const { loadingInfo } = infoList;


    return(
        <>
        {loadingPictures ? (
            <div className="loading">Loading</div>

        ) : (         
            <>   
            { matchPoints.md ?
            <div
            className="prints__menu menu">
                <h3 className="menu-title">Prints ({picturesList.pictures.length})</h3>
            </div> 
            :
            <div className="prints__menu menu">
                <Link to="/">Home</Link>
                <h3 className="menu-title">Prints ({picturesList.pictures.length})</h3>
                <Link className="right" to="/films">Go to films</Link>
            </div> 
            }
            <a className="link-shop" target="_blank" rel='noopener noreferrer' href="https://shop.maximeellies.com">Shop prints</a>

            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} 
            className="container__prints" >
                {picturesList.pictures.map((picture, i) => (
                    <a target="_blank" rel='noopener noreferrer' href="https://shop.maximeellies.com" key={i} className={`container__picture ${picture.fields.landscape ? "container__picture--landscape" : ""}`}>
                        <SinglePicture picture={picture} landscape={picture.fields.landscape}/>
                    </a>
                ))}
            </motion.div>
            {loadingInfo ?
                ""
                :
                <FooterBar matchPoints={matchPoints} email={infoList.info[0].fields.email}/>
            }

            </>
        )}
        </>
    )
    
}
  

export default PrintsScreen;
