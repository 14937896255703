import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FooterBar from "../components/FooterBar";
import { motion } from "framer-motion";


const HomeScreen = (props) => {
  const matchPoints = props.matchPoints;

  const infoList = useSelector((state) => state.infoList);
  const {loadingInfo} = infoList;
    return(
        <>
        {loadingInfo ? 
            <div className="loading">
                <span>Loading</span>
            </div>
          :
          <>
          <div className="home__sections">
                <Link className="link" to="/prints">Prints</Link>
                {matchPoints && matchPoints.md ? "" : <Link className="link title" to="/">Maxime Ellies</Link> }
                <Link className="link right" to="/films">Films</Link>
              </div>
            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} 
            className="container__home">
              <div className="home__background" style={{backgroundImage: `url(${infoList.info[0].fields.background.fields.file.url})`}}>
              </div>
              
            </motion.div>
              <FooterBar isHomePage={true} matchPoints={matchPoints} email={infoList.info[0].fields.email}/>
              </>
            }
        </>
          
    )
}
  

export default withRouter(HomeScreen)
