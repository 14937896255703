import React from 'react';

const VideoPlayer = (props) => {
    const currentTime = props.currentTime.toFixed(0);
    const duration = props.duration.toFixed(0);
    const percentage = props.currentTime / props.duration * 100;
    const progressPoints = [];

    for (let index = 0; index < 10; index++) {
        const point = index
        progressPoints.push(point)
    }

    const currentTimeStyle = {
        left: `${percentage}%`,
    }

    return(
        <div className="container__playerVideo">
            <div className="playerVideo__isPlaying">
                <span onClick={() => props.stopVideo()}>{props.videoPlaying ? "Play" : "Pause"}</span>
            </div>
            <div className="playerVideo__progress">
                <span className="progress__value" style={currentTimeStyle}>
                
                </span>
                <div className="progress__points">
                    {progressPoints.map((i) => (
                        <span className="point" key={i}></span>
                    ))}
                </div>
            </div>
            <div className="playerVideo__totalTime">
                <span>0:{currentTime.toString().length === 1 ? `0${currentTime}` : currentTime} / 0:{duration}</span>
            </div>
        </div>
    )
}

export default VideoPlayer;