/**
* Portfolio CONSTANTS
*/
export const PICTURES_LIST_REQUEST = 'PICTURES_LIST_REQUEST'
export const PICTURES_LIST_SUCCESS = 'PICTURES_LIST_SUCCESS'
export const PICTURES_LIST_FAIL = 'PICTURES_LIST_FAIL'

export const INFO_REQUEST = 'INFO_REQUEST'
export const INFO_SUCCESS = 'INFO_SUCCESS'
export const INFO_FAIL = 'INFO_FAIL'

export const VIDEOS_LIST_REQUEST = 'VIDEOS_LIST_REQUEST'
export const VIDEOS_LIST_SUCCESS = 'VIDEOS_LIST_SUCCESS'
export const VIDEOS_LIST_FAIL = 'VIDEOS_LIST_FAIL'