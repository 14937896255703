/**
* Portfolio Actions
*/
import {
    PICTURES_LIST_REQUEST, 
    PICTURES_LIST_SUCCESS, 
    PICTURES_LIST_FAIL
  
  } from '../constants/constants'
  
  import {client} from "../client"
  
  
  const getPicturesEntries = client.getEntries({
    'content_type': 'picturesOrganisation'
  });

  const listPictures = () => (dispatch) => {
    try{
      dispatch({ type: PICTURES_LIST_REQUEST, loadingPictures: true });
    getPicturesEntries.then(pictures => {
            dispatch({ type: PICTURES_LIST_SUCCESS, payload: pictures.items[0].fields.assets, loadingPictures: false });
        });
    }catch (error) {
      dispatch({ type: PICTURES_LIST_FAIL, payload: error.message });
    }
  }
  
  export {
    listPictures
  }