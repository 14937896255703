import { useState, useEffect } from 'react';

const queries = {
  xs: "(max-width: 320px)",
  md: "(max-width: 768px)",
  lg: "(max-width: '1024px)" 
}

const useBreakpoint = () => {
    const [queryMatch, setQueryMatch] = useState(null);
  
    useEffect(() => {
      const mediaQueryLists = {};
      const keys = Object.keys(queries);
      
      let isAttached = false;
  
      const handleQueryListener = () => {
        const updatedMatches = keys.reduce((acc, media) => {
          acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
          return acc;
        }, {})
        setQueryMatch(updatedMatches)
      }
  
      if (window && window.matchMedia) {
        const matches = {};
        keys.forEach(media => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media] = window.matchMedia(queries[media]);
            matches[media] = mediaQueryLists[media].matches
          } else {
            matches[media] = false
          }
        });
        setQueryMatch(matches);
        isAttached = true;
        keys.forEach(media => {
          if(typeof queries[media] === 'string') {
            mediaQueryLists[media].addListener(handleQueryListener);
          }
        });
      }
  
      return () => {
        if(isAttached) {
          keys.forEach(media => {
            if(typeof queries[media] === 'string') {
              mediaQueryLists[media].removeListener(handleQueryListener);
            }
          });
        }
      }
    }, []);
  
    return queryMatch;
  }
  
  export default useBreakpoint;