import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import { motion } from "framer-motion";

const transition = { duration: 0.4, ease: [0.6, 0.01, -0.05, 0.9] };

const FooterBar = (props) => {
    const matchPoints = props.matchPoints;
    const [link, setLink] = useState("films");
    const [linkText, setLinkText] = useState("Go to");

    if(matchPoints.md && props.isFilmPage && link === "films") {
        setLink("prints");
        setLinkText("Go to");

    }
    if(matchPoints.md && !props.isFilmPage && link === "prints") {
        setLink("films")
        setLinkText("Go to");
    }
    return(
        <>
        {props.isFilmPage && props.metadata && !matchPoints.md ? 
        <div className="footer__bar">
            <VideoPlayer duration={props.metadata.duration}  currentTime={props.currentTime} videoPlaying={props.videoPlaying} stopVideo={props.stopVideo}/>
        </div>
        :
        props.isInfoPage && matchPoints.md ? 
            <div className="footer__bar">
                <div className="footer__content">
                        <Link to="/prints">Go to prints</Link>
                        <Link className="right" to="/films">Go to films</Link>
                </div>
            </div>
        :
        matchPoints.md ? 
        <div className="footer__bar">
                <div className="footer__content">
                    <Link to="info">Info</Link>
                    <span>{props.isHomePage ? "Maxime Ellies" : ""}</span>
                        {props.isHomePage ?
                        <a className="right" href={`mailto: ${props.email}`}>Mail</a>
                        :
                        <Link className="right" to={link}>{linkText} {link}</Link>
                    }

                </div>
            </div>
        :
        props.isInfoPage ? 
            <motion.div 
            initial={{ transform: "translateY(0%)" }}
            animate={{ transform: "translateY(100%)" }}
            exit={{ transform: "translateY(0%)" }} 
            transition={transition} 
            className="footer__bar footer__bar--info"></motion.div>
            :
            <div className="footer__bar">
                <div className="footer__content">
                    <Link to="/info">Info</Link>
                    <span>Creative Director</span>
                    <a className="right" href={`mailto:${props.email}`}>Contact</a>
                </div>
            </div>
        }
        </>
    ) 
}   

export default FooterBar;