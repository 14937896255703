import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FooterBar from "../components/FooterBar";
import { motion } from "framer-motion";



const FilmScreen = (props) => {
    const matchPoints = props.matchPoints;
    const videosList = useSelector((state) => state.videosList);

    const containerVideos = useRef();
    
    const {loadingVideos} = videosList;
    
    const [videoPlaying, setVideoPlaying] = useState(true);

    const [currentImage, setCurrentImage] = useState(null);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [lastVideo, setLastVideo] = useState(null);

    const [currentTime, setCurrentTime] = useState(0);

    const [metadata, setMetadata] = useState(null);

    const itemsRef = useRef([]);
    const imagesRef = useRef([]);
    const otherVideos = useRef([]);

    const scrollArrow = useRef();
    const scrollTrigger = useRef();



    const changeVideoOnScroll = (e) => {
        let index = itemsRef.current.length;
        if(itemsRef.current[0] !== null) {
            while(--index && window.scrollY + 200 < itemsRef.current[index].offsetTop) {
            }
            
            itemsRef.current.forEach((link) => link.classList.remove('active'));
            itemsRef.current[index].classList.add('active');
            setActiveVideo(otherVideos.current[index]);
            setCurrentImage(imagesRef.current[index + 1]);

        }
    }
    
    const hideArrowOnScroll = () => {
        scrollArrow.current.classList.add("hidden");
    }

    

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            if(scrollArrow.current) {
                if(!scrollArrow.current.classList.contains("hidden")){
                    hideArrowOnScroll(); 
                }
            }
            changeVideoOnScroll(e)
        });

        if (currentVideo !== lastVideo && lastVideo) {
            lastVideo.classList.remove("active");
            currentVideo.classList.add("active");
            lastVideo.pause();
            currentVideo.oncanplaythrough = function() {
                if(currentImage)
                currentImage.classList.remove('active');
                
            };
            currentVideo.play()
            setVideoPlaying(true);
        }
        return () => {
        };

      }, [currentVideo, lastVideo]);

    useEffect(() => {
        if(otherVideos.current[0] && !currentVideo){
            setActiveVideo(otherVideos.current[0]);
            setCurrentImage(imagesRef.current[0]);
        }  
    })

    const setActiveVideo = (element) => {
        setCurrentVideo(element);
        setLastVideo(element);
        updateMetadata(element);
    }

    const updateMetadata = (element) => {
        if(element.target) {
            setMetadata({
                videoHeight: element.target.videoHeight,
                videoWidth: element.target.videoWidth,
                duration: element.target.duration,
              });
              updateCurrentTime(element.target);
        }
    }

    const updateCurrentTime = (element) => {
        if(element.target) {
            setCurrentTime(element.target.currentTime)
        }
    }

    const stopVideo = () => {
        setVideoPlaying(!videoPlaying);
        videoPlaying ? currentVideo.pause(): currentVideo.play();
    }

    return(
        <>
        {loadingVideos ? (
            <div className="loading">Loading</div>
        ) : 
        (
            <>
            { matchPoints.md ?
            <div className="prints__menu menu">
                <h3 className="menu-title">Videos ({videosList.videos.length})</h3>
            </div> 
            :
            <div className="films__menu menu">
                <Link to="/">Home</Link>
                <h3 className="menu-title">Videos ({videosList.videos.length})</h3>
                <Link className="right" to="/prints">Go to prints</Link>
            </div> 
            }
            <div className="scrolldown__container" ref={scrollArrow}>
                {/* <span>scroll</span> */}
                <i className="arrow"></i>
            </div>
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }} 
                className="container__films" ref={containerVideos} onScroll={(e) => {
                    
                }}>
                <span className="scrollTrigger" ref={scrollTrigger}></span>
                {videosList.videos.map((video, i) => (
                    <a key={i} href={video.fields.link}>
                        <div className={`container__video ${i} ${i === 0 ? "active" : ""}`} ref={el => (itemsRef.current[i] = el)} >
                            <h3 className='video__title'>{video.fields.title} </h3>
                                <img ref={el => (imagesRef.current[i] = el)} alt="" className="video__image active" src={video.fields.backgroundImage.fields.file.url}></img>
                                <video ref={el => (otherVideos.current[i] = el)} className="video" src={video.fields.project.fields.file.url} {...i === 0 ? {autoPlay: true} : {}} muted loop playsInline preload="none"
                                onLoadedMetadata={e => {
                                    updateMetadata(e);
                                }}
                                onTimeUpdate={e => {
                                    updateCurrentTime(e);
                                }}
                                ></video>
                        </div>
                    </a>
                ))}
            </motion.div>
            <FooterBar isFilmPage={true} video={currentVideo} metadata={metadata} videoPlaying={!videoPlaying} currentTime={currentTime} matchPoints={matchPoints} stopVideo={() => stopVideo()}/>
            </>
        )}
        </>
          
    )
}
  

export default withRouter(FilmScreen)
