import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen';
import FilmsScreen from './screens/FilmsScreen';
import PrintsScreen from './screens/PrintsScreen';
import InfoScreen from './screens/InfoScreen';

import { AnimatePresence } from "framer-motion";
import useBreakpoint from './utils/useBreakpoint';

function App() {
  const matchPoints = useBreakpoint();

  
  return (

    <BrowserRouter>
        <Route
          render={({ location }) => {
            
            return (
              <AnimatePresence initial={false} exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  <Route exact path='/' render={(props) => <HomeScreen {...props} matchPoints={matchPoints} />} />
                  <Route path='/films' render={(props) => <FilmsScreen {...props} matchPoints={matchPoints} />}/>
                  <Route path='/prints' render={(props) => <PrintsScreen {...props} matchPoints={matchPoints} />}/>
                  <Route path='/info' render={(props) => <InfoScreen {...props} matchPoints={matchPoints} />}/>
                  <Route path='/login' component={() => { 
                      window.location.href = "https://be.contentful.com/login"; 
                      return null;
                  }}/>
                </Switch>
              </AnimatePresence>
            );
          }}
        />
    </BrowserRouter>
  );
}


export default App;
