import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from "framer-motion";
import FooterBar from "../components/FooterBar";


const InfoScreen = (props) => {
    const infoList = useSelector((state) => state.infoList);
    const {loadingInfo} = infoList;
    const matchPoints = props.matchPoints;

    return(
        <>
            {loadingInfo ?
                <div className="loading">Loading</div>

            : 
            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }} 
            className="container__info">
                <div className="info__background">
                    <div className="image" style={{backgroundImage: `url(${infoList.info[0].fields.background.fields.file.url})`}}></div>
                    <div className="overlay"></div>
                </div>
                <div className="info__content">
                    <div className="links">
                        <NavLink to="/">Back home</NavLink>
                        {
                            !matchPoints.md ?
                            <NavLink className="download" to="/prints">Go to prints</NavLink> 
                            :
                            ""
                        }
                    </div>
                    <div className="about">
                        <div className="text">
                            <h3 className="title">{infoList.info[0].fields.infoTitle}</h3>
                            <p className="description">{infoList.info[0].fields.infoDescription}</p>
                        </div>
                        <a className="mail" href={`mailto: ${infoList.info[0].fields.email}`}>{infoList.info[0].fields.email}</a>
                    </div>
                </div>
            </motion.div>
            }
            {loadingInfo ?
                ""
                : 
                <FooterBar isInfoPage={true} matchPoints={matchPoints}/>
            }


        </>
    )
}

export default InfoScreen;