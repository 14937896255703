import React from 'react';

const SinglePicture = (props) => {
    return(
        <div className={`container__asset ${props.landscape ? "picture--landscape" : ""}`}>
                <img alt={props.picture.fields.title} className="image" src={props.picture.fields.project.fields.file.url}></img>
        </div>
    )
}

export default SinglePicture;